<template>
  <div id='about'>
    <div class="box_banner">
      <div class="box_banner_mid">
          <p class="banner_mid_title">{{$t('about.bannerTitel')}}  </p>
          <p class="banner_mid_value">{{$t('about.bannerVallue')}}</p>
      </div>
    </div>   
    <!-- 关于云链 -->
    <div class="main_aboutus">
      <div class="about_content">
        <p class="title">{{$t('about.aboutTitle')}}</p>
        <p class="text">{{$t('about.aboutVallue')}}</p>
      </div>
    </div>
    <!-- 核心优势 -->
    <div class="main_advantage">
      <div class="about_content">
        <p class="about_title">{{$t('about.advantageTitle')}}</p>
        <ul class="advantage_ul">
          <li class="advantage_li" v-for="(item,index) in advantage" :key="index">
            <img :src="item.img" alt="">
            <div class="textbox">
              <p class="text_tit">{{item.tit}}</p>
              <p class="text_tet">{{item.tet}}</p>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <!-- 资质认证 -->
    <div class="main_attestation">
      <div class="about_content">
        <p class="about_title">{{$t('about.qualification')}}</p>
        <div class="about_swiperbox">
          <p @click="arrowClick(2)" class="attestation_icon attestation_left">
            <img src="../assets/about_swiper_l.png" alt="">
          </p>
          <div class="about_swiper">
            <el-carousel :autoplay="false" indicator-position="none" arrow="never" ref="cardShow">
              <el-carousel-item v-for="(item,index) in allarry" :key="index">
                <ul class="attestation_ul">
                  <li class="attestation_li" v-for="(it,i) in item" :key="i">
                    <div class="top_img">
                      <el-image :src="it.img" :preview-src-list="imgarr"></el-image>
                    </div>
                    <p class="bom_text" v-html="it.tet"></p>
                  </li>
                </ul>
              </el-carousel-item>
            </el-carousel>
          </div>
          <p @click="arrowClick(1)" class="attestation_icon attestation_right">
            <img src="../assets/about_swiper_r.png" alt="">
          </p>
        </div>
      </div>
    </div>
    <div class="main_develop">
      <div class="about_content">
        <p class="about_title">{{$t('about.attestationTitle')}}</p>
        <img src="../assets/about_path.png" alt="" class="about_img">
        <div class="line"></div>
      </div>
    </div>
    <!-- <img src="../assets/about.png" alt="" class="about_img"> -->
  </div>
</template>
<script>
export default {
  data() {
    return {
      imgarr: [],

    };
  },
  computed:{
    // 核心优势
    advantage(){
      return  [
        {
          img: require("../assets/about_item1.png"),
          tit: this.$t('about.advantageList'),
          tet:this.$t('about.advantageCont')
        },
        {
          img: require("../assets/about_item2.png"),
          tit: this.$t('about.advantageList2'),
          tet:this.$t('about.advantageCont2')
        },
        {
          img: require("../assets/about_item3.png"),
          tit: this.$t('about.advantageList3'),
          tet:this.$t('about.advantageCont3')
        },
        {
          img: require("../assets/about_item4.png"),
          tit: this.$t('about.advantageList4'),
          tet:this.$t('about.advantageCont4')
        },
      ]
    },
    allarry(){
      return [
        [
          {
            img: require("../assets/about_img1.png"),
            tet: this.$t('about.attestationList'),
          },
          {
            img: require("../assets/about_img2.png"),
            tet: this.$t('about.attestationList2'),
          },
          {
            img: require("../assets/about_img3.png"),
            tet: this.$t('about.attestationList3'),
          },
          {
            img: require("../assets/about_img4.png"),
            tet: "coinance",
          }
        ],
        [
          {
            img: require("../assets/about_img5.png"),
            tet: this.$t('about.attestationList4'),
          },
          {
            img: require("../assets/about_img6.png"),
            tet: this.$t('about.attestationList5'),
          },
          {
            img: require("../assets/about_img7.png"),
            tet: this.$t('about.attestationList6'),
          },
          {
            img: require("../assets/about_img8.png"),
            tet: this.$t('about.attestationList7'),
          }
        ],
        [
          {
            img: require("../assets/about_img9.png"),
            tet: this.$t('about.attestationList8'),
          },
          {
            img: require("../assets/about_img10.png"),
            tet: this.$t('about.attestationList9'),
          },
          {
            img: require("../assets/about_img11.png"),
            tet: this.$t('about.attestationList10'),
          },
          {
            img: require("../assets/about_img12.png"),
            tet: this.$t('about.attestationList11'),
          }
        ],
        [
          {
            img: require("../assets/about_img13.png"),
            tet: this.$t('about.attestationList12'),
          },
          {
            img: require("../assets/about_img14.png"),
            tet: this.$t('about.attestationList13'),
          },
          {
            img: require("../assets/about_img15.png"),
            tet: this.$t('about.attestationList14'),
          },
          {
            img: require("../assets/about_img16.png"),
            tet: this.$t('about.attestationList15'),
          }
        ],
        [
          {
            img: require("../assets/about_img17.png"),
            tet: this.$t('about.attestationList16'),
          },
          {
            img: require("../assets/about_img18.png"),
            tet: this.$t('about.attestationList17'),
          },
          {
            img: require("../assets/about_img19.png"),
            tet: this.$t('about.attestationList18'),
          }
        ],
      ]
    }
  },
  mounted() {
    // let imgarr = [];
    // this.attestation.forEach((item) => {
    //   imgarr.push(item.img);
    // });
    // this.imgarr = imgarr;

    // let allarry = [];
    // let nums = this.attestation.length % 4;
    // let arry = [];
    // if (nums > 0) {
    //   arry = this.attestation.splice(-nums);
    //   allarry.push(arry);
    // }
    // let nusss = this.attestation.length / 4;
    // for (let i = 1; i <= nusss; i++) {
    //   let arrys = this.attestation.splice(0, 4);
    //   allarry.unshift(arrys);
    // }
    // console.log(allarry);
    // this.allarry = allarry;
  },
  methods: {
    arrowClick(val) {
      if (val === 1) {
        this.$refs.cardShow.next();
      } else {
        this.$refs.cardShow.prev();
      }
    },
  },
};
</script>
<style lang='scss' scoped>
#about {
  .main_banner {
    height: 437px;
    background-image: url("../assets/banner.png");
    background-size: 100% 100%;
  }
  .about_content {
    width: 1200px;
    margin: 0 auto;
    .about_title {
      font-size: 36px;
      //   font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 600;
      color: #1d2023;
      margin: 68px 0 40px;
    }
  }
  .main_aboutus {
    height: 311px;
    background-image: url("../assets/about_back.png");
    background-size: 100% 100%;
    .title {
      font-size: 36px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 600;
      color: #1d2023;
      padding: 55px 0 16px;
    }
    .text {
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #606972;
      line-height: 30px;
    }
  }
  .main_advantage {
    height: 513px;
    .about_content {
      .advantage_ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .advantage_li {
          width: 580px;
          height: 140px;
          padding: 34px 28px;
          background: #ffffff;
          box-shadow: 0px 0px 13px 0px rgba(86, 124, 210, 0.16);
          display: flex;
          align-items: center;
          box-sizing: border-box;
          margin-bottom: 40px;
          img {
            width: 60px;
            height: 60px;
          }
          .textbox {
            width: 452px;
            margin-left: 12px;
            .text_tit {
              font-size: 16px;
              font-weight: 600;
              color: #1d2023;
              margin-bottom: 10px;
            }
            .text_tet {
              font-size: 14px;
              font-weight: 400;
              color: #606972;
            }
          }
        }
      }
    }
  }
  .main_attestation {
    height: 513px;

    .about_swiperbox {
      display: flex;
      justify-content: space-around;
      align-items: center;
      .attestation_icon {
        width: 49px;
        height: 49px;
        background: #ffffff;
        box-shadow: 0px 1px 4px 0px rgba(171, 171, 171, 0.5);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 50px;
        img {
          width: 33.23px;
          height: 33.23px;
        }
      }
      .about_swiper {
        height: 100%;
        width: 958px;
        .el-carousel__item {
          height: 100%;
          width: 958px;
          .attestation_ul {
            display: flex;
            justify-content: space-between;
            .attestation_li {
              width: 242px;
              display: flex;
              flex-direction: column;
              align-items: center;
              .top_img {
                width: 187px;
                height: 211px;
                background: #ffffff;
                box-shadow: 0px 0px 6px 1px rgba(201, 201, 201, 0.38);
                border-radius: 1px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-bottom: 32px;
                img {
                  max-width: 143px;
                  max-height: 193px;
                }
                /deep/ .el-image__error,
                /deep/ .el-image__inner,
                /deep/ .el-image__placeholder {
                  width: auto;
                  max-width: 143px;
                  height: auto;
                  max-height: 193px;
                }
              }
              .bom_text {
                width: 220px;
                text-align: center;
                font-size: 16px;
                line-height: 22px;
                font-weight: 400;
                color: #1d2023;
              }
            }
          }
        }
      }
    }
  }
  .main_develop {
    height: 787px;
    position: relative;
  }
}
.about_img {
  width: 1200px;
  height: 561px;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 86px;
}
.line {
  position: absolute;
  left: 0;
  right: 0;
  top: 338px;
  height: 4px;
  background-color: #3075ff;
}
.box_banner{
    width: 100%;
    height: 437px;
    background-image: url("../assets/banner_img.png");
    background-size: 100% 437px;
}
.box_banner_mid{
    width: 1200px;
    margin: auto;
    color: #FFFFFF;
    font-size: 46px;
    font-weight: 600;
    position: relative;
    padding-top: 140px;
    box-sizing: border-box;
}
.banner_mid_title span{
     color: #78A0FF;
     font-size: 38px;
}
.banner_mid_value{
    font-size: 16px;
    font-weight: 400;
    margin-top: 16px;   
    // text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.49);
}
</style>